<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4>Página inicial</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div v-if="homePage">
          <Referral :referrals="homePage.referrals" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="card card-checkin">
          <div class="card-body">
            <h4 class="mb-3">
              <i class="fas fa-map-marked-alt mr-1"></i> Fazer check-in
            </h4>
            <h5 class="mb-4">
              Compartilhe em suas redes sociais com os seus amigos que você está
              utilizando o QuestõesPRO!
            </h5>
            <div class="mb-4">
              <ShareNetwork
                v-for="network in [
                  'facebook',
                  'twitter',
                  'whatsapp',
                  'telegram',
                ]"
                :key="network"
                :network="network"
                :url="socialShare.url"
                :title="socialShare.title"
                :description="socialShare.description"
                :quote="socialShare.quote"
                :hashtags="socialShare.hashtags"
              >
                <b-button pill variant="dark" size="lg" class="mr-1">
                  <i :class="`mdi mdi-${network} mdi-24px`"></i>
                </b-button>
              </ShareNetwork>
              <!-- <b-button
                pill
                variant="dark"
                size="lg"
                class="mr-1"
                v-b-modal="`instagram-modal`"
                ><i class="mdi mdi mdi-instagram mdi-24px"></i
              ></b-button> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12">
        <div v-if="interestStats" class="card">
          <div class="card-body">
            <h4 class="mb-3">Oportunidades de análise</h4>
            <div class="table-responsive mb-0">
              <table class="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col" class="interest-name-col">Interesse</th>
                    <th scope="col">
                      Questões<br>
                      pendentes<br>
                      de análise
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="stat in interestStats" :key="stat.id">
                    <td class="interest-name-col">
                      {{ stat.name }}
                    </td>
                    <td>{{ stat.questions - stat.reviewCount }}</td>
                    <td>
                      <div>
                        <router-link
                          :to="{
                            name: 'questions',
                            query: { interestId: stat.id },
                          }"
                          class="btn btn-primary btn-sm"
                        >
                          Ver
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="card">
          <div class="card-body">
            <TwitterTimeline />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <b-modal
        id="instagram-modal"
        size="xl"
        title="Compartilhar"
        body-class="text-center"
        ok-only
      >
        <ShareableImage background="default" />
      </b-modal>
    </div>
  </Layout>
</template>

<script>
import ShareableImage from "@/components/ShareableImage";
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import * as api from "@/api";
//import stripHtml from "string-strip-html";
import { mapState } from "vuex";

import Referral from "@/components/Referral";
import TwitterTimeline from "@/components/TwitterTimeline";

export default {
  page: {
    title: "Página inicial",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    Referral,
    ShareableImage,
    TwitterTimeline,
  },

  data() {
    return {
      interestStats: null,
      homePage: null,
    };
  },

  created() {
    api.getInterestsStats().then((interestStats) => {
      this.interestStats = interestStats;
    });

    api.getHomePage().then((homePage) => {
      this.homePage = homePage;
    });
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    socialUrl() {
      const route = this.$router.resolve({
        name: "home",
      });

      if (!route) {
        return;
      }

      return window.location.origin + route.href;
    },

    socialShare() {
      const baseTitle = "Venha para o QuestõesPRO!";
      const title =
        baseTitle.length > 70 ? `${baseTitle.slice(0, 66)}...?` : baseTitle;
      const description = `@${this.currentUser.username}, está usando o QuestõesPRO!`;
      return {
        url: this.socialUrl,
        // Twitter, WhatsApp
        title,
        // WhatsApp
        description,
        // Facebook
        quote: description,
        // Facebook, Twitter
        hashtags: "questoespro",
      };
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.card-checkin {
  height: 325px;
  @media screen and (max-width: 768px) {
    height: auto;
  }
}

td.interest-name-col {
  width: 50%;
  max-width: 50%;
  word-wrap: break-word!important;
  word-break: break-word;
  white-space: normal;
}
</style>
